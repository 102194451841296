/* eslint-disable*/
// Require CSS
require('./themes/EasyAgentPro/out/new.css');
require('./mu-plugins/easyagentpro/Explore/Resources/css/main.css');
require('./mu-plugins/easyagentpro/Explore/Resources/scss/explorearchive.scss');
require('./themes/EasyAgentPro/out/404.css');

require('./plugins/jquery-smooth-scroll/js/script.min.js');

var js = require.context('./mu-plugins/easyagentpro/Templates/templates', true, /js$/);
js.keys().forEach(js);
var scss = require.context('./mu-plugins/easyagentpro/Templates/templates', true, /scss$/);
scss.keys().forEach(scss);
