(function ($) {
	'use strict';
	$(document).ready(function () {

		var template = {
			init: function () {
				// Tab Reveal
				$("#tab1-details").show();
				$(".reveal").on("click", function (e) {
					e.preventDefault();
					var id = $(e.target).attr("id");
					$(".tab").hide();
					$("#" + id + "-details").show();
				});
				if (this.isBreakPoint(768)) {
					$(".tab").addClass(".tab-block");
				}
			},
			isBreakPoint: function (bp) {
				var bps = [768, 769],
					w = $(window).width(),
					min,
					max;
				for (var i = 0, l = bps.length; i < l; i++) {
					if (bps[i] === bp) {
						min = bps[i - 1] || 0;
						max = bps[i];
						break;
					}
				}
				return w > min && w <= max;
			}
		}
		if ($('#eap_lp_mm_2').length) {
			template.init();
		}

	});
})(jQuery);
