(function ($) {
	'use strict';
	$(document).ready(function () {

		var template = {
			init: function () {
				// put your js in here.
				// it will not run unless on the page
				// with the id
			}
		}
		if ($('#eap_lp_mm_5').length) {
			template.init();
		}

	});
})(jQuery);
