(function ($) {
	'use strict';
	$(document).ready(function () {

		var template = {
			init: function () {
        $('#exitIntentPopup').submit(function(e){
          e.preventDefault();
          $.ajax({
            url:'https://hooks.zapier.com/hooks/catch/479798/askrar/silent',
            type:'post',
            data:$('#exitIntentPopup').serialize(),
            success:function(){
              // Redirect to another success page
              window.location = "https://easyagentpro.com/leadsites-1";
            }
          });
        });
			}
		}
		if ($('#eap_lp_mm_3').length) {
			template.init();
		}

	});
})(jQuery);
